import getPageProps from '@/helpers/getPageProps';
import { NextPage, NextPageContext } from 'next';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';
import { createStyles, Container, Title, Group, Stack } from '@mantine/core';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import replaceUndefinedWithNull from '@/helpers/replaceUndefinedWithNull';
import sanitizeObject from '@/helpers/sanitizeObject';
import Link from '@/components/Link/Link';
import useStaticContent from '@/utils/hook/useStaticContent';
import LocaleHelper from '@/helpers/Locale';
import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';
import DataLayerHelper from '@/helpers/Datalayer';

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: 80,
    paddingBottom: 80,
  },

  inner: {
    position: 'relative',
  },

  image: {
    ...theme.fn.cover(),
    opacity: 0.75,
  },

  content: {
    position: 'relative',
    zIndex: 1,

    [theme.fn.smallerThan('sm')]: {
      paddingTop: 120,
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    textAlign: 'center',
    fontWeight: 900,
    fontSize: 38,

    [theme.fn.smallerThan('sm')]: {
      fontSize: 32,
    },
  },

  description: {
    maxWidth: 540,
    margin: 'auto',
    marginTop: theme.spacing.xl,
    marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
  },
}));

interface Props {
  statusCode?: number;
}

const Error: NextPage<Props> = ({ statusCode }) => {
  const { classes } = useStyles();
  const staticContent = useStaticContent();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      DataLayerHelper.pushDataLayerEvent('VirtualPageview', {
        currency: 'EUR',
        // @ts-ignore
        currentPage: '404',
        // @ts-ignore
        currentPagetype: 'Error 404',
      });
    }
    if (statusCode !== 410) Sentry.captureMessage('Error 404');
    console.error('capture message 404');
  }, []);

  return (
    <Container className={classes.root}>
      <div className={classes.inner}>
        <Stack>
          <Title
            order={1}
            size={60}
            align='center'>
            {statusCode || 404}
          </Title>
          <NoozText
            align='center'
            className={classes.title}>
            {staticContent.errorPageLabel}
          </NoozText>
          <NoozText
            color='dimmed'
            size='lg'
            align='center'
            className={classes.description}>
            {staticContent.errorPageSubLabel}
          </NoozText>
          <Link href={`/`}>
            <Group position='center'>
              <NoozButton>{staticContent.backToHomeLabel}</NoozButton>
            </Group>
          </Link>
        </Stack>
      </div>
    </Container>
  );
};

export async function getServerSideProps({ res, err }: NextPageContext) {
  const url = res?.req?.url;
  console.debug('>>>', url);
  // @ts-ignore
  if (res && url && (/nooz-optics\.com/.test(url) || /404\?/.test(url)))
    res.statusCode = 410;

  const statusCode = res ? res.statusCode : err ? err.statusCode : 400;
  const [, locale] = (url || '/en-int')?.split('/');

  const newProps = await getPageProps(
    { slugId: '25k8500Re1cD4EkM4MHp6C', pageId: '7LyywaP6JMVbChlgdIAhdL' }, // 500 or 404
    locale,
    LocaleHelper.getValidContentfulLocale(locale),
  );

  console.error('<Error.getInitialProps> getServerSideProps', { url });

  return replaceUndefinedWithNull(
    sanitizeObject({ props: { ...newProps.props, statusCode, incoming: url } }),
  );
}

export default Error;
